import {
  ADJUST_TYPE,
  AGENT_ADJUST_STATE,
  DEPOSIT_STATE,
  ENABLE,
  FRONT_COPY_TYPE,
  PROMOTION_REBATE_STATE,
  SEO_PAGE,
  SLIDE_TYPE,
  TRANSFER_SETTING_STATUS,
  WITHDRAW_STATE,
} from "enum/state";
import i18n from "i18n";
import { convertedToPercentage01, isNumber, toFormatNumber } from "utils/common";
import { Image, Typography } from "antd";
import { WALLET_TRANSLATE, WITHDRAW_TYPE } from "constants/gateway";

// 開發人員二
export const operationCase2 = (combinedId: string, input: any, _data: any, site: any, tagList: any, memberTagList: any, type: string) => {
  if (input === '-') {
    const isForce = _data?.IsMerchantExisted === 1
    switch (combinedId) {
      /* SEO設置 -> 新增、編輯、刪除 */
      case '59-50402':
      case '59-50403':
      case '59-50405':
        return (
          <>
            <div>{i18n.t('page')}：{i18n.t(SEO_PAGE[_data?.Type])}</div>
            <div>{i18n.t('title')}：{_data?.Title}</div>
            <div>{i18n.t('keyword')}：{_data?.KeyWord}</div>
            <div>{i18n.t('description')}：{_data?.Description}</div>
            <div>{i18n.t('head meta')}：{_data?.HeadMeta}</div>
            <div>{i18n.t('head link')}：{_data?.HeadLink}</div>
            <div>{i18n.t('htmlContent')}：{_data?.HtmlContent}</div>
          </>
        )
      /* 輪播圖 -> 新增、編輯、刪除 */
      case '57-50202':
      case '57-50203':
      case '57-50204':
        return (
          <>
            <div>{i18n.t('category')}：{i18n.t(SLIDE_TYPE[_data?.Category])}</div>
            <div>{i18n.t('image')}：<div><Image src={_data?.Photo_1} height={40} /></div></div>
            <div>{i18n.t('openLinkInNewWindow')}：{_data?.LinkTarget ? i18n.t('yes') : i18n.t('none')}</div>
            <div>{i18n.t('linkURL')}：{_data?.LinkURL}</div>
            <div>{i18n.t('status')}：{i18n.t(ENABLE[_data?.Status])}</div>
            <div>{i18n.t('sort')}：{_data?.Position}</div>
          </>
        )
      /* 代理標籤 -> 新增、編輯 */
      case '55-41102':
      case '55-41103':
        return (
          <>
            <div>{i18n.t('tagName')}：{_data?.Name}</div>
            <div>{i18n.t('showTagName')}：{_data?.IsShowName === 1 ? i18n.t('display') : i18n.t('displayNone')}</div>
            <div>{i18n.t('tagFontColor')}：{_data?.TextColor}</div>
            <div>{i18n.t('tagBackgroundColor')}：{_data?.Color}</div>
            <div>{i18n.t('sort')}：{_data?.Position}</div>
            <div>{i18n.t('description')}：{_data?.Description || '-'}</div>
            <div>{i18n.t('status')}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 代理標籤 -> 代理帳號編輯 */
      case '55-41105':
        return (
          <>
            {_data?.Type && <Typography.Text strong>
              {_data?.Type === 'batch' ? i18n.t('batchRelease') : i18n.t('removeTag')}
            </Typography.Text>}
            <div>{i18n.t('tagName')}：{_data?.TagName}</div>
            <div>{i18n.t('agentAccount')}：{_data?.Account}</div>
          </>
        )
      /* 前台文案 -> 新增、編輯、刪除 */
      case '56-50102':
      case '56-50103':
      case '56-50104':
        return (
          <>
            <Typography.Text strong>{_data?.Category === 'Main' ? i18n.t('mainTitle') : i18n.t('subTitle')}</Typography.Text>
            <div>{i18n.t('category')}：{i18n.t(FRONT_COPY_TYPE[_data?.Type])}</div>
            <div>{i18n.t('mainTitle')}：{_data?.MainTitle || _data?.Title}</div>
            {_data?.Category === 'Sub' && <div>{i18n.t('subTitle')}：{_data?.Title}</div>}
            {_data?.Category === 'Sub' && <div>{`${i18n.t('internalContent')}／${i18n.t('image')}`}：{
              _data?.Photo ? <div><Image src={_data?.Photo} height={40} /></div> : _data?.Content
            }</div>}
            <div>{i18n.t('sort')}：{_data?.Position}</div>
          </>
        )
      /* 返水優惠查詢 -> 審核 */
      case '43-30402':
        return (
          <>
            {_data?.Status === PROMOTION_REBATE_STATE.dispatched && <div>{i18n.t('distributeAll')}</div>}
            {_data?.Status === PROMOTION_REBATE_STATE.reject && <div>{i18n.t('batchReject')}</div>}
            {(!_data?.Status || _data?.Status === PROMOTION_REBATE_STATE.pendingDistribution) && '-'}
          </>
        )
      /* 調帳查詢 -> 新增、審核 */
      case '29-20303':
      case '29-20304':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account || _data?.OwnerAccount}</div>
            <div>{i18n.t('transactionType')}：{i18n.t(ADJUST_TYPE[_data?.Type])}</div>
            <div>{i18n.t('amount')}：{toFormatNumber(_data?.Amount)}</div>
            <div>{i18n.t('rolloverRate')}：{_data?.ValidBetMultiple}</div>
            <div>{i18n.t('status')}：{i18n.t(AGENT_ADJUST_STATE[_data?.Status])}</div>
            <div>{i18n.t('backOfficeRemarks')}：{_data?.Remark || '-'}</div>
          </>
        )
      /* 提款查詢 -> 審核-客服審核、審核-主管審核、審核-財務審核 */
      case '27-20104':
      case '27-20106':
      case '27-20108':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            {type === 'before' && <div>{i18n.t('status')}：{ i18n.t(WITHDRAW_STATE[_data?.Status])}</div>}
            {type === 'after' && <div>{i18n.t('status')}：{
              (_data?.Status !== WITHDRAW_STATE.fail && _data?.Status !== WITHDRAW_STATE.reject) ? i18n.t('approvaled') : i18n.t('reject')}</div>}
            {[WITHDRAW_STATE.pendingServiceReview, WITHDRAW_STATE.pendingSupervisorReview, WITHDRAW_STATE.pendingFinancialReview].includes(_data?.Status)
              // 審核時不顯示
              ? <></>
              : <>
                {(_data?.GatewayCode?.includes('BANK') || isForce) && <>
                  {<div>{i18n.t('withdrawalType')}：{
                    // 出款方式只有'拒絕'後端會回傳null(強制拒絕也會回傳資料)
                    _data?.WithdrawMerchantGatewayCode !== null ? WITHDRAW_TYPE[_data?.WithdrawMerchantGatewayCode]  : '-'}</div>}
                  {<div>{i18n.t('withdrawalChannel')}：{
                    // 出款渠道只有'拒絕'後端會回傳null(強制拒絕也會回傳資料)
                    _data?.WithdrawName !== null ? _data?.WithdrawName : '-'}</div>}
                </>}
                {_data?.GatewayCode?.includes('CRYPTO') && <>
                  {<div>{i18n.t('currency')}：{i18n.t(_data?.Crypto)}</div>}
                  {<div>{i18n.t('networkType')}：{i18n.t(_data?.CryptoType)}</div>}
                  {<div>{`${i18n.t('displayName')}-${i18n.t('userAddress')}`}：{
                    !(_data?.Status === WITHDRAW_STATE.fail) && !(_data?.Status === WITHDRAW_STATE.reject) ? _data?.WithdrawName : '-'}</div>}
                </>}
              </>}
            <div>{i18n.t('frontDeskRemarks')}：{_data?.FnRemark || '-'}</div>
            <div>{i18n.t('backOfficeRemarks')}：{_data?.FinanceAccessLogRemark || _data?.AuditorAccessLogRemark || _data?.ServiceAccessLogRemark || '-'}</div>
          </>
        )
      /* 入款查詢 -> 審核-財務審核 */
      case '28-20203':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            <div>{i18n.t('status')}：{i18n.t(DEPOSIT_STATE[_data?.Status])}</div>
            <div>{i18n.t('backOfficeRemarks')}：{_data?.BnRemark || '-'}</div>
          </>
        )
      /* 代理提款查詢 -> 審核-客服審核、審核-主管審核、審核-財務審核 */
      case '49-40504':
      case '49-40506':
      case '49-40508':
        return (
          <>
            <div>{i18n.t('agentAccount')}：{_data?.Account}</div>
            {type === 'before' && <div>{i18n.t('status')}：{ i18n.t(WITHDRAW_STATE[_data?.Status])}</div>}
            {type === 'after' && <div>{i18n.t('status')}：{
              (_data?.Status !== WITHDRAW_STATE.fail && _data?.Status !== WITHDRAW_STATE.reject) ? i18n.t('approvaled') : i18n.t('reject')}</div>}
            {[WITHDRAW_STATE.pendingServiceReview, WITHDRAW_STATE.pendingSupervisorReview, WITHDRAW_STATE.pendingFinancialReview].includes(_data?.Status)
              // 審核時不顯示
              ? <></>
              : <>
                {(_data?.GatewayCode?.includes('BANK') || isForce) && <>
                  {<div>{i18n.t('withdrawalType')}：{
                    // 出款方式只要拒絕就是'-'
                    _data?.Status === WITHDRAW_STATE.reject || !(_data?.WithdrawMerchantGatewayCode)
                    ? '-' : WITHDRAW_TYPE[_data?.WithdrawMerchantGatewayCode]}</div>}
                  {<div>{i18n.t('withdrawalChannel')}：{
                    // 出款渠道雖然線下要顯示WithdrawName 但因為是null 所以還是'-'
                    _data?.Status === WITHDRAW_STATE.reject || !(_data?.WithdrawName)
                    ? '-' : _data?.WithdrawName}</div>}
                </>}
                {_data?.GatewayCode?.includes('CRYPTO') && <>
                  {<div>{i18n.t('currency')}：{_data?.Status !== WITHDRAW_STATE.reject ? i18n.t(_data?.Crypto) : '-'}</div>}
                  {<div>{i18n.t('networkType')}：{_data?.Status !== WITHDRAW_STATE.reject ? i18n.t(_data?.CryptoType) : '-'}</div>}
                  {<div>{`${i18n.t('displayName')}-${i18n.t('userAddress')}`}：{
                    !(_data?.Status === WITHDRAW_STATE.fail) && !(_data?.Status === WITHDRAW_STATE.reject) ? _data?.WithdrawName : '-'}</div>}
                </>}
              </>}
            <div>{i18n.t('frontDeskRemarks')}：{_data?.FnRemark || '-'}</div>
            <div>{i18n.t('backOfficeRemarks')}：{_data?.FinanceAccessLogRemark || _data?.AuditorAccessLogRemark || _data?.ServiceAccessLogRemark || '-'}</div>
          </>
        )
      /* 三方出入款管理-提款 -> 編輯 */
      /* 三方出入款管理-入款 -> 編輯 */
      case '36-21003':
      case '38-21203':
        return(
          <>
            <div>{i18n.t("thirdPartName")}：{_data?.Name}</div>
            <div>{i18n.t("displayName")}：{_data?.CustomName}</div>
            <div>{i18n.t("channelName")}：{WALLET_TRANSLATE[_data?.GatewayCode]}</div>
            <div>{i18n.t("cashFlowRate")}：{convertedToPercentage01(_data?.Rate)}</div>
            <div>{i18n.t("processingFee")}：{toFormatNumber(_data?.HandlingFee)}</div>
            <div>{i18n.t("applicableMemberLevel")}：{_data?.LevelNames?.join(" / ") || i18n.t("ALL")}</div>
            <div>{combinedId.includes("36-") ? i18n.t("minWithdrawingAmount") : i18n.t("minDepositAmount")
            }：{isNumber(_data?.Minimum) ? toFormatNumber(_data?.Minimum) : "-"}</div>
            <div>{combinedId.includes("36-") ? i18n.t("maxWithdrawingAmount") : i18n.t("maxDepositAmount")
            }：{isNumber(_data?.Maximum) ? toFormatNumber(_data?.Maximum) : "-"}</div>
            {combinedId === "38-21203" &&
              <div>{i18n.t("qrCode")}：{_data?.QR_Code ? <Image src={_data?.QR_Code} height={40} /> : "-"}</div>
            }
            <div>{i18n.t("status")}：{i18n.t(TRANSFER_SETTING_STATUS[_data?.Status])}</div>
            <div>{i18n.t("sort")}：{_data?.Position}</div>
          </>
        )
      default:
        return ('-')
    }
  } else return input;
}