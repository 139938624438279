import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from "antd/lib/form/Form";
import { DatePickerCol, LayoutNav, LayoutPagination } from 'components/layout.component';
import { managementi18n, operationi18n } from 'constants/permission';
import dayjs from 'dayjs';
import { PERMISSION_STATE } from 'enum/state';
import { useOperationData } from 'hooks/operation.hook';
import i18n from 'i18n';
import React, { useState } from 'react';
import { $get } from 'services';
import { selectFuzzyFilter } from 'utils/common';

// 操作記錄(新)

const PageMain: React.FC = () => {
  const { transformData } = useOperationData();
  const [form] = useForm();
  const [disabled, setDisabled] = useState(true);
  const [keyId, setKeyId] = useState<string>('');
  const [page, setPage] = useState<number[]>([1, 10]);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD'),
    dayjs().endOf('day').format('YYYY-MM-DD')
  ]);
  const [reset, setReset] = useState(false);
  const [params, setParams] = useState<any>({
    StartDate: `${date[0]} 00:00:00`,
    EndDate: `${date[1]} 23:59:59`,
  });

  // 聖經表單改變
  // 第一次進來會因為時間初始化過 但條件一樣導致搜尋不反應
  const [formChanged, setFormChanged] = useState(false);
  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    if (formChanged) {
      setFormChanged(false);
      setParams({
        StartDate: `${date[0]} 00:00:00`,
        EndDate: `${date[1]} 23:59:59`,
        ManagementId: formData.KeyId,
        PermissionId: formData.PermissionId,
        OperationId: formData.OperationId,
        Account: formData.Account,
      });
    } else mutate();
  };

  const onClear = () => {
    setPage([1, 10]);
    setReset(!reset);
    setDisabled(true);
    form.resetFields();
  }

  const handleKeyIdChange = (selected: string) => {
    if (selected) {
      setDisabled(false);
      setKeyId(selected);
    }
    else { setDisabled(true); }
    form.setFieldValue('OperationId', null);
  }

  // 取得紀錄
  const { data: OperationList, isValidating, mutate } = $get({
    url: 'api/operationlog/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    }
  });

  // 權限列表
  const { data: permission } = $get({
    url: 'api/permission/list',
    params: {
      PageIndex: 1,
      PageSize: 999,
      Status: PERMISSION_STATE.enabled,
    }
  });

  // 權限項目
  const { data: operationList } = $get({
    url: 'api/permission/operation/list'
  });

  const operationOptions = (keyId && operationList)
    ? operationList.Data
      .filter((item: any) => item.ManagementId === Number(keyId) && (
        !operationi18n[item.OperationId].includes(i18n.t('view')) &&
        !operationi18n[item.OperationId].includes(i18n.t('accounting'))
      ))
      .map((item: any) => ({ value: item.OperationId, label: operationi18n[item.OperationId] }))
    : []

  // 完成的操作記錄
  const finishItem = [
    // 會員
    '14', '15', '16', '17', '18', '19',
    '21', '22', '23', '80', '81', '89', '90',
    // 會員IP
    '24', '25', '26',
    // 財務查詢
    '27', '28', '29', '30', '33', '36', '38', '91', '92',
    // 全民代理
    '86', '87', '88',
    // 優惠
    '43', '82', '83', '41', '42', '76',
    // 代理系列
    '45', '46', '49', '50', '51', '52', '53', '54', '55',
    // 前台頁面
    '56', '57', '59',
    '58', '75', '85',
    // 統計報表
    '62', '63', '66', '67', '72', '60',
    // 站台設置
    '1', '2', '3', '4', '5', '6', '93', '8',
    '9', '11'
  ];

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish} onValuesChange={setFormChanged}>
          <Row gutter={[12, 12]}>
            {/* 搜尋 */}
            {/* 第一行 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="KeyId">
                    <Select
                      showSearch
                      filterOption={selectFuzzyFilter}
                      className="w-full"
                      placeholder={`${i18n.t('page')}`}
                      onChange={e => handleKeyIdChange(e)}
                      options={[
                        { value: '', label: `${i18n.t('ALL')}` },
                        ...finishItem.map(key => ({ value: key, label: managementi18n[key] as string }))
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="OperationId">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('operation')}`}
                      disabled={disabled}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...operationOptions
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="PermissionId">
                    <Select
                      className="w-full"
                      placeholder={`${i18n.t('backOfficePermissions')}`}
                      options={[
                        { value: '', label: i18n.t('ALL') },
                        ...((permission && permission.Data) || []).map((item: any) => ({
                          value: item.Id, label: item.Name
                        }))
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="Account">
                    <Input placeholder={`${i18n.t('backOfficeAccount')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 第二行 */}
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <DatePickerCol width={193} date={date} setDate={setDate} initDate={reset} format={'YYYY-MM-DD'}
                  setFormChanged={setFormChanged} />
                <Col><Button type="primary" htmlType="submit">{i18n.t('search')}</Button></Col>
                <Col><Button onClick={onClear}>{i18n.t('clear')}</Button></Col>
              </Row>
            </Col>
            {/* 列表 */}
            <Col span={24}>
              <Table
                loading={isValidating}
                dataSource={
                  OperationList?.Data?.map((item: OperationInfo) => ({
                    ...item, key: item.Id, KeyId: Number(item.KeyId)
                  }))}
                columns={[
                  {
                    dataIndex: 'ManagementId',
                    title: i18n.t('page'),
                    width: 160,
                    render: (val) => managementi18n[val]
                  },
                  {
                    dataIndex: 'OperationId',
                    title: i18n.t('operation'),
                    width: 160,
                    render: (val) => operationi18n[val]
                  },
                  {
                    dataIndex: 'BeforeData',
                    title: i18n.t('beforeEdit'),
                    width: 370,
                    render: (val, { ManagementId, OperationId }: any) => transformData(val, ManagementId, OperationId, 'before')
                  },
                  {
                    dataIndex: 'AfterData',
                    title: i18n.t('edited'),
                    width: 370,
                    render: (val, { ManagementId, OperationId }) => transformData(val, ManagementId, OperationId, 'after')
                  },
                  {
                    dataIndex: 'CreateDate',
                    title: i18n.t('operationTime'),
                    width: 140,
                  },
                  {
                    dataIndex: 'PermissionName',
                    title: i18n.t('backOfficePermissions'),
                    width: 140,
                    render: (val) => val || 'System'
                  },
                  {
                    dataIndex: 'Operator',
                    title: i18n.t('backOfficeAccount'),
                    width: 80,
                    render: (val) => val || 'System'
                  },
                  {
                    dataIndex: 'IP',
                    title: 'IP',
                    width: 80,
                    render: (val, { Operator }) => Operator ? val : '-'
                  }
                ]}
                pagination={false}
              />
              <LayoutPagination total={OperationList?.TotalRecord || 0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;