import { Image, Tooltip, Typography } from "antd";
import { TagTableDisplay } from "components/account.component";
import { agenti18n } from "constants/permission";
import { COUPON_STATUS } from "enum/coupon";
import { SETTLE_CYCLE } from "enum/promotion";
import {
  ADJUST_TYPE,
  AGENT_ADJUST_STATE,
  AGENT_STATE,
  AGENT_WITHDRAW_STATE,
  BONUS_TICKET_TYPE,
  CONTACT_INFO,
  DEPOSIT_STATE, DISCOUNT_TYPE,
  ENABLE,
  IS_OFFLINE,
  MEMBER_BANK_STATE,
  MEMBER_FREEZE_REPORT, MEMBER_GROUP_LOCK_STATUS_YES_NONE,
  MESSAGE_TEMPLATE_STATE, PEOPLE_AGENT_STATUS,
  PERMISSION_STATE,
  REBATE_PERIOD,
  SOURCE_ROLE,
  TRANSFER_SETTING_STATUS,
} from "enum/state";
import i18n from "i18n";
import { $get } from "services";
import {
  convertedToPercentage01 as $g,
  agentLevelWord,
  convertedToPercentage01,
  convertGameSettings,
  gameNameTransfer,
  isNumber,
  specialProviderName,
  timeS2L,
  toFormatNumber, timeL2SDay,
} from "utils/common";
import { MESSAGE_TEMPLATE_TYPE, MESSAGE_TEMPLATE_TYPE_STATUS } from "../constants/message-template";
import { operationCase2 } from "./operation.sub.hook";
import useSite from "./site.hook";
import { FRON_FRONT_DESK_ITEM_CODE, FRON_POP_ITEM_CODE } from "pages/admin/front/popup";
import { CHANGE_MEMBER_METHOD, SETTLE_METHOD } from "enum/role";
import React from "react";
import dayjs from "dayjs";
import { WALLET_TRANSLATE } from "constants/gateway";

export const useOperationData = () => {
  const site = useSite();
  // 標籤列表
  const { data: tagList } = $get({ url: 'api/tag/list' });

  const { data: memberTagList } = $get({ url: 'api/tag/member/list' });

  const transformData = (data: any, ManagementId: number, OperationId: number, type: string) => {
    const combinedId = `${ManagementId}-${OperationId}`;
    const _data = JSON.parse(data);

    /* 空物件轉為'-' */
    if (Object.keys(_data).length === 0) return '-'

    let content = null;
    content = operationCase1(combinedId, content, _data, site, tagList, memberTagList, type);
    content = operationCase2(combinedId, content, _data, site, tagList, memberTagList, type);

    return (
      <div style={{ maxHeight: 200, overflow: 'hidden', overflowY: 'auto' }}>
        {content}
      </div>
    )
  }
  return { transformData }
}

// 開發人員一
const operationCase1 = (combinedId: string, input: any, _data: any, site: any, tagList: any, memberTagList: any, type: string) => {
  if (input === null) {
    // NOTO: OperationId 不重複 但保留 ManagementId 方便閱讀
    switch (combinedId) {
      /* 會員列表 -> 新增 */
      case '14-10102':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.MemberAccount}</div>
            <div>{i18n.t('password')}：********</div>
            <div>{i18n.t('agent')}：{_data?.AgentAccount}</div>
            <div>{i18n.t('nickname')}：{_data?.NickName || '-'}</div>
          </>
        )
      /* 會員列表 -> 備註-編輯 */
      /* 會員列表-基本資料 -> 備註-新增、備註-編輯、備註-刪除 */
      case '14-10103':
      case '15-10204':
      case '15-10205':
      case '15-10206':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            <div>{i18n.t('remark')}：{_data?.Noted || '-'}</div>
          </>
        )
      /* 會員列表 -> 匯出 */
      case '14-10104':
        return '-'
      /* 會員列表 -> 批次設定 */
      case '14-10105':
        if (!Array.isArray(_data)) return;
        else return (
          <>
            {_data.map((item: { MemberAccount: string, IsFreeze: number }, index: number) => (
              <div key={index}>
                <div>{i18n.t('memberAccount')}：{item.MemberAccount}</div>
                <div>{i18n.t('status')}：{i18n.t(MEMBER_FREEZE_REPORT[item.IsFreeze])}</div>
              </div>
            ))}
          </>
        )
      /* 會員列表-基本資料 -> 編輯 */
      case '15-10202':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            <div>{i18n.t('realName')}：{_data?.Name || '-'}</div>
            <div>{i18n.t('id')}：{_data?.IDNumber || '-'}</div>
            <div>{i18n.t('phoneNumber')}：{_data?.PhonePrefix && _data?.PhoneNumber
              ? `${_data?.PhonePrefix} ${_data?.PhoneNumber}`
              : '-'}</div>
            <div>{i18n.t('email')}：{_data?.Email}</div>
            <div>{i18n.t('birthday')}：{_data?.Birth !== '0000-00-00' ? _data?.Birth : '-'}</div>
            <div>{i18n.t('memberLevel')}：
              {_data?.IsLockLevel ? `${i18n.t('lockLevel')}-` : ''}{_data?.LevelName}</div>
            <div>{i18n.t('agent')}：{_data?.AgentAccount}</div>
            <div>{i18n.t('status')}：{i18n.t(MEMBER_FREEZE_REPORT[_data?.Freeze])}</div>
            <div>{i18n.t('password')}：********</div>
            <div>{i18n.t('promotionCode')}：{_data?.RefCode}</div>
            <div>{i18n.t('remark')}：{_data?.Remark || '-'}</div>
            <div>{i18n.t('createTime')}：{timeS2L(_data?.CreateDate)}</div>
            <div>{i18n.t('lastLoginTime')}：{_data?.LastLoginDate ? timeS2L(_data?.LastLoginDate) : '-'}</div>
            <div>{i18n.t('verificationTime')}：{
              _data?.VerifiedDate !== '0000-00-00 00:00:00' ? timeS2L(_data?.VerifiedDate) : '-'
            }</div>
            <div>{i18n.t('IP')}：{_data?.IP || '-'}</div>
            <div>{i18n.t('nickname')}：{_data?.NickName || '-'}</div>
            <div>{i18n.t('identifier')}：{_data?.NoCode}</div>
            <div>{i18n.t("memberTag")}：<TagTableDisplay ids={_data?.TagsId || "[]"} list={memberTagList} /></div>
          </>
        )
      /* 會員列表-遊戲商資料 -> 編輯 */
      case '16-10302':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.MemberAccount}</div>
            <div>{specialProviderName(_data?.ProviderName, site?.data?.SiteName, _data?.ProviderCode)}
              -{i18n.t(_data?.CategoryCode)}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 會員列表-遊戲商資料 -> 批次設定 */
      case '16-10303':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            {_data?.Provider.map((item: any) => (
              <div key={item.Id}>
                <div>{specialProviderName(item.ProviderName, site?.data?.SiteName, item.ProviderCode)}
                  -{i18n.t(item.CategoryCode)}：{i18n.t(ENABLE[item.Status])}</div>
              </div>
            ))}
          </>
        )
      /* 會員列表-聯絡資訊 -> 新增、編輯、刪除 */
      case '17-10402':
      case '17-10403':
      case '17-10404':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.MemberAccount}</div>
            <div>{i18n.t('source')}：{i18n.t(SOURCE_ROLE[_data?.SourceRole])}</div>
            <div>{i18n.t('account')}：{_data?.SourceAccount}</div>
            <div>{i18n.t('contactMethod')}：{i18n.t(CONTACT_INFO[_data?.ContactInfo])}</div>
            <div>{i18n.t('content')}：{_data?.ContactContent}</div>
            <div>{i18n.t('status')}：{i18n.t(ENABLE[_data?.Status])}</div>
            <div>{i18n.t('image')}：{!_data?.Photo && '-'}</div>
            <div>{_data?.Photo && <Image src={_data?.Photo} height={40} />}</div>
          </>
        )
      /* 會員列表-銀行卡列表 -> 新增、刪除、編輯 */
      case '18-10503':
      case '18-10504':
      case '18-10505':
        if (_data.Data?.Crypto) {
          return (
            <>
              <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
              <div>{i18n.t('currency')}：{_data?.Data?.Crypto || '-'}</div>
              <div>{i18n.t('networkType')}：{_data?.Data?.CryptoType || '-'}</div>
              <div>{i18n.t('userAddress')}：{_data?.Data?.CryptoAddress || '-'}</div>
              <div>{i18n.t('qrCode')}：{
                JSON.parse(_data?.Data?.Photo)[0] ? <div><Image src={JSON.parse(_data?.Data?.Photo)[0]} height={40} /></div> : '-'
              }</div>
              <div>{i18n.t('status')}：{i18n.t(MEMBER_BANK_STATE[_data?.Data?.Status])}</div>
            </>
          )
        } else {
          return (
            <>
              <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
              <div>{i18n.t('bankName')}：{_data?.Data?.BankName || '-'}</div>
              <div>{i18n.t('accountNumber')}：{_data?.Data?.Account}</div>
              <div>{i18n.t('bankAccountName')}：{_data?.Data?.Name}</div>
              <div>{i18n.t('branchName')}：{_data?.Data?.Branch || '-'}</div>
              <div>{i18n.t('bankCounty')}：{_data?.Data?.State || '-'}</div>
              <div>{i18n.t('city')}：{_data?.Data?.City || '-'}</div>
              <div>{i18n.t('phoneNumber')}：{
                (_data?.Data?.PhonePrefix && _data?.Data?.PhoneNumber)
                  ? `${_data?.Data?.PhonePrefix} ${_data?.Data?.PhoneNumber}` : '-'
              }</div>
              <div>{i18n.t('status')}：{i18n.t(MEMBER_BANK_STATE[_data?.Data?.Status])}</div>
            </>
          )
        }
      /* 會員列表-返水列表 -> 編輯 */
      case '19-10602':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            {/* <div>{i18n.t('rebatePeriod')}：{i18n.t(REBATE_PERIOD[_data?.Data?.CommissionSettleCycle])}</div> */}
            <div>{i18n.t('status')}：{i18n.t(ENABLE[_data?.Data?.Status])}</div>
            {/* <div>{i18n.t('gameCategory')} - {i18n.t('rebateRate')}%</div> */}
            {/* <div>{i18n.t('EGAMES')}：{convertedToPercentage01(_data?.Data?.EGamesCommissionRate)}</div>
            <div>{i18n.t('LIVE')}：{convertedToPercentage01(_data?.Data?.LiveCommissionRate)}</div>
            <div>{i18n.t('SPORTS')}：{convertedToPercentage01(_data?.Data?.SportsCommissionRate)}</div>
            <div>{i18n.t('ESPORTS')}：{convertedToPercentage01(_data?.Data?.ESportsCommissionRate)}</div>
            <div>{i18n.t('POKER')}：{convertedToPercentage01(_data?.Data?.PokerCommissionRate)}</div>
            <div>{i18n.t('FISHING')}：{convertedToPercentage01(_data?.Data?.CockfightCommissionRate)}</div>
            <div>{i18n.t('LOTTERY')}：{convertedToPercentage01(_data?.Data?.LotteryCommissionRate)}</div>
            <div>{i18n.t('SLOT')}：{convertedToPercentage01(_data?.Data?.SlotCommissionRate)}</div> */}
          </>
        )
      /* 實名驗證 -> 新增、編輯、審核 */
      case '21-10802':
      case '21-10803':
      case '21-10804':
        return (
          <>
            <div>{i18n.t('account')}：{_data?.Account}</div>
            <div>{i18n.t('id')}：{_data?.IDNumber}</div>
            <div>{i18n.t('name')}：{_data?.Name}</div>
            <div>
              <div>{i18n.t('uploadPaperwork')}：</div>
              <div>{_data?.Photo_1 && <Image src={_data?.Photo_1} height={40} />}</div>
            </div>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
              <div>{i18n.t('uploadPaperwork')}：</div>
              <div>{_data?.Photo_2 && <Image src={_data?.Photo_2} height={40} />}</div>
            </div>
            <div>{i18n.t('status')}：{i18n.t(AGENT_ADJUST_STATE[_data?.Status])}</div>
          </>
        )
      /* 銀行卡驗證 -> 審核 */
      case '22-10903':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            <div>{i18n.t('realName')}：{_data?.RealName || '-'}</div>
            <div>{i18n.t('accountName')}：{_data?.Data?.Name}</div>
            <div>{i18n.t('phoneNumber')}：{
              (_data?.Data?.PhonePrefix && _data?.Data?.PhoneNumber)
                ? `${_data?.Data?.PhonePrefix} ${_data?.Data?.PhoneNumber}` : '-'
            }</div>
            <div>{i18n.t('accountNumber')}：{_data?.Data?.Account}</div>
            <div>{i18n.t('createTime')}：{timeS2L(_data?.Data?.CreateDate)}</div>
            <div>{i18n.t('image')}：{
              JSON.parse(_data?.Data?.Photo)[0] ? <div><Image src={JSON.parse(_data?.Data?.Photo)[0]} height={40} /></div> : '-'
            }</div>
            <div>{i18n.t('image')}：{
              JSON.parse(_data?.Data?.Photo)[1] ? <div><Image src={JSON.parse(_data?.Data?.Photo)[1]} height={40} /></div> : '-'
            }</div>
            <div>{i18n.t('status')}：{i18n.t(MEMBER_BANK_STATE[_data?.Data?.Status])}</div>
          </>
        )
      /* 出入款渠道設定-出入款 -> 編輯 */
      case '91-21402':
      case '91-21403':
      case '92-21502':
      case '92-21503':
        return (
          <>
            <div className="font-w-md">{i18n.t('groupName')}：{i18n.t(_data?.MemberGroupName)}</div>
            <div className="font-w-md">{i18n.t(combinedId.includes('91-') ? 'withdrawalChannel' : 'depositChannel')}</div>
            <div className="font-w-md">{i18n.t('type')}：{i18n.t(_data?.GatewayCode.includes('OFFLINE') ? 'transfer' : 'thirdParty')}</div>
            <div className="font-w-md">{`${i18n.t('channelName')} - ${i18n.t('displayName')}`}：{`${WALLET_TRANSLATE[_data?.GatewayCode]} - ${_data?.CustomName}`}</div>
            <div>{i18n.t(combinedId.includes('91-') ? 'MaximumWithdrawalsPerDay': 'MaximumDepositPerDay')}：{toFormatNumber(_data?.OperateTimesPerDay, 0) || '-'}</div>
            {!combinedId.includes('91-') &&
            <div>{i18n.t('MinimumDepositSingle')}：{toFormatNumber(_data?.MinAmountPerTrans) || '-'}</div>}
            <div>{i18n.t(combinedId.includes('91-') ? 'MaximumWithdrawalSingle': 'MaximumDepositSingle')}：{toFormatNumber(_data?.MaxAmountPerTrans)}</div>
            <div>{i18n.t('status')}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 即時訊息 -> 新增 */
      case '23-11002':
        return (
          <>
            <div>{i18n.t('subject')}：{_data?.Title}</div>
            <div>{i18n.t('content')}：{_data?.Content}</div>
            <div>{i18n.t('recipient')}：{_data?.Account}</div>
          </>
        )
      /* 即時訊息-訊息模板 -> 設定、編輯、刪除 */
      case '81-11502':
      case '81-11503':
      case '81-11504':
        return (
          <>
            <div>{i18n.t("type")}：{i18n.t(MESSAGE_TEMPLATE_TYPE[_data?.Type])}</div>
            <div>{i18n.t("typeStatus")}：{i18n.t(MESSAGE_TEMPLATE_TYPE_STATUS[_data?.TypeStatus])}</div>
            <div>{i18n.t("subject")}：{_data?.Title}</div>
            <div>{i18n.t("content")}：{_data?.Content}</div>
            <div>{i18n.t("status")}：{i18n.t(MESSAGE_TEMPLATE_STATE[_data?.Status])}</div>
          </>
        )
      /* 會員IP檢核 -> 凍結會員 */
      case '24-11102':
        return (
          <>
            <div>{i18n.t('memberAccount')}：</div>
            {_data?.Account?.length !== 0 && _data?.Account?.map((account: string, i: number) => (
              <div key={i}>{account}</div>
            ))}
            {_data?.MemberAccounts?.length !== 0 && _data?.MemberAccounts?.map((account: string, i: number) => (
              <div key={i}>{account}</div>
            ))}
          </>
        )
      /* 會員IP檢核 -> 封鎖IP、解除封鎖IP */
      case '24-11103':
      case '24-11104':
        return (
          <>
            <div>{i18n.t('IP')}：{_data?.IP}</div>
          </>
        )
      /* 會員IP檢核 -> IP封鎖管理 -> 新增、編輯、刪除 */
      case '25-11202':
      case '25-11203':
      case '25-11204':
        return (
          <>
            <div>{i18n.t('status')}：{i18n.t('blocked')}</div>
            <div>{i18n.t('remark')}：{_data?.Remark || '-'}</div>
          </>
        )
      /* 遊戲IP檢核 -> 新增 */
      case '26-11302':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            <div>{i18n.t('IP')}：{_data?.IP}</div>
            <div>{i18n.t('status')}：{i18n.t('blocked')}</div>
            <div>{i18n.t('remark')}：{_data?.Remark || '-'}</div>
          </>
        )
      /* 遊戲IP檢核 -> 備註-編輯 */
      case '26-11303':
        return (
          <>
            <div>{i18n.t('memberAccount')}：{_data?.Account}</div>
            <div>{i18n.t('remark')}：{_data?.Noted}</div>
          </>
        )
      /* 轉帳出入款管理-提款/入款 -> 新增 */
      /* 轉帳出入款管理-提款/入款 -> 編輯 */
      /* 轉帳出入款管理-提款/入款 -> 刪除 */
      case '30-20402':
      case '30-20403':
      case '30-20404':
      case '33-20702':
      case '33-20703':
      case '33-20705':
        return (
          <>
            {_data?.QuickAmount && _data?.QuickAmount?.length !== 0
              ? <>{_data?.QuickAmount?.map((amount: number, i: number) => (
                <div key={i}>{i18n.t('quickAmount')}{i + 1}：{toFormatNumber(amount)}</div>
              ))}</>
              : (_data?.Crypto
                ? <><div>{i18n.t('currency')}：{_data?.Crypto}</div>
                  <div>{i18n.t('networkType')}：{_data?.CryptoType}</div>
                  <div>{i18n.t('displayName')}：{_data?.CustomName}</div>
                  <div>{i18n.t('userAddress')}：{_data?.CryptoAddress}</div>
                  <div>{i18n.t('cashFlowRate')}：{convertedToPercentage01(_data?.Rate)}</div>
                  <div>{i18n.t('processingFee')}：{toFormatNumber(_data?.HandlingFee)}</div>
                  <div>{i18n.t('exchangeRate')}：{toFormatNumber(_data?.ExchangeRate)}</div>
                  <div>{i18n.t('applicableMemberLevel')}：{_data?.LevelNames?.join(' / ') || i18n.t('ALL')}</div>
                  <div>{combinedId.includes('30-') ? i18n.t('minWithdrawingAmount') : i18n.t('minDepositAmount')
                  }：{isNumber(_data?.Minimum) ? toFormatNumber(_data?.Minimum) : '-'}</div>
                  <div>{combinedId.includes('30-') ? i18n.t('maxWithdrawingAmount') : i18n.t('maxDepositAmount')
                  }：{isNumber(_data?.Maximum) ? toFormatNumber(_data?.Maximum) : '-'}</div>
                  <div>{i18n.t('status')}：{i18n.t(TRANSFER_SETTING_STATUS[_data?.Status])}</div>
                  <div>{i18n.t('sort')}：{_data?.Position}</div>
                  <div>{i18n.t("qrCode")}：{_data?.QR_Code ? <Image src={_data?.QR_Code} height={40} /> : "-"}</div>
                </>
                : <><div>{i18n.t('bankName')}：{_data?.BankName}</div>
                  <div>{i18n.t('displayName')}：{_data?.CustomName}</div>
                  <div>{i18n.t('accountName')}：{_data?.Name}</div>
                  <div>{i18n.t('accountNumber')}：{_data?.Account}</div>
                  <div>{i18n.t('branchName')}：{_data?.Branch}</div>
                  <div>{i18n.t('bankCounty')}：{_data?.State || '-'}</div>
                  <div>{i18n.t('countryCode')}：{_data?.PhonePrefix || '-'}</div>
                  <div>{i18n.t('phoneNumber')}：{_data?.PhoneNumber || _data?.Phone || '-'}</div>
                  <div>{i18n.t('city')}：{_data?.City || '-'}</div>
                  <div>{i18n.t('cashFlowRate')}：{convertedToPercentage01(_data?.Rate)}</div>
                  <div>{i18n.t('processingFee')}：{toFormatNumber(_data?.HandlingFee)}</div>
                  <div>{i18n.t('applicableMemberLevel')}：{_data?.LevelNames?.join(' / ') || i18n.t('ALL')}</div>
                  <div>{combinedId.includes('30-') ? i18n.t('minWithdrawingAmount') : i18n.t('minDepositAmount')
                  }：{isNumber(_data?.Minimum) ? toFormatNumber(_data?.Minimum) : '-'}</div>
                  <div>{combinedId.includes('30-') ? i18n.t('maxWithdrawingAmount') : i18n.t('maxDepositAmount')
                  }：{isNumber(_data?.Maximum) ? toFormatNumber(_data?.Maximum) : '-'}</div>
                  <div>{i18n.t('status')}：{i18n.t(TRANSFER_SETTING_STATUS[_data?.Status])}</div>
                  <div>{i18n.t('sort')}：{_data?.Position}</div>
                  <div>{i18n.t("qrCode")}：{_data?.QR_Code ? <Image src={_data?.QR_Code} height={40} /> : "-"}</div>
                  <div>{i18n.t("frontDeskRemarks")}：{_data?.FnDescription || "-"}</div>
                  <div>{i18n.t("backOfficeRemarks")}：{_data?.BnDescription || "-"}</div>
                </>)}
          </>
        )
      /* 代理列表-編輯 -> 編輯、新增 */
      case '46-40203':
      case '46-40202': // 新增總代
      case '46-40205': // 新增代理
        const info = combinedId === '46-40203' ? _data.Data : _data;
        return (
          <>
            {info.Password && <>
              {combinedId === '46-40202' && <Typography.Text strong>{i18n.t('generalAgent')}</Typography.Text>}
              {combinedId === '46-40205' && <Typography.Text strong>{i18n.t('agent')}</Typography.Text>}
              <div>{i18n.t('account')}：{info.Account}</div>
              <div>{i18n.t('password')}：{info.Password}</div>
              <div>{i18n.t('confirmPassword')}：{info.ConfirmPassword}</div>
              <div>{i18n.t('status')}：{i18n.t(AGENT_STATE[combinedId === '46-40203' ? info.Status : info.Statu])}</div>
              {combinedId === '46-40203' &&
                <div>{i18n.t('verificationDate')}：{
                  !!info.VerifiedDate && info.VerifiedDate !== '0000-00-00 00:00:00' ? timeS2L(info.VerifiedDate) : '-'
                }</div>}
              <div>{i18n.t('agentTag')}：<TagTableDisplay ids={info.TagsId || []} list={tagList} /></div>
              {/* 優惠占成 */}
              {(combinedId === '46-40202' || combinedId === '46-40205') && <>
                <div>{`${i18n.t('promotionRevenue')}%`}：{$g(info.BonusCostRate)}</div>
                <div>{i18n.t('paymentFeeRate')}：{$g(info.CashFlowFeeRate)}</div>
              </>}
              <div>{i18n.t('remark')}：{info.Remark || '-'}</div>

              <Typography.Text strong>{i18n.t('backOfficePermissions')}</Typography.Text>
              {info.Permission?.length > 0
                ? agenti18n(site.isCashVersion, site.data).map((item: any, i: number) => {
                  const permission = info.Permission;
                  const activation = item.child.filter((_: any) => permission.includes(_.key));
                  const appellation = activation.map((_: any) => _.name).join(', ');

                  return appellation.length > 0
                    ? <div key={i}>{`${item.MenuName} > ${item.name}：${appellation}`}</div> : <span key={i}></span>
                })
                : <div>-</div>
              }

              <Typography.Text strong>{i18n.t('cashbackCommissionSetting')}</Typography.Text>
              <div>{i18n.t('rebateSettlementPeriod')}：{i18n.t(SETTLE_CYCLE[info.CommissionSettleCycle])}</div>
              <div>{i18n.t('revenueSettlementPeriod')}：{i18n.t(SETTLE_CYCLE[info.RevenueShareSettleCycle])}</div>
            </>}
            {_data?.Type === 'OperationParams' && <>
              {/* 返水 Commission */}
              <div>{`${i18n.t('promotionRevenue')}%`}：{$g(info.BonusCostRate)}</div>
              <div>{i18n.t('paymentFeeRate')}：{$g(info.CashFlowFeeRate)}</div>
              <Typography.Text strong>{`${i18n.t('gameCategory')}-${i18n.t('rebateRate')}%`}</Typography.Text>
              <div>{i18n.t('SLOT')}：{$g(info.SlotCommissionRate)}</div>
              <div>{i18n.t('LIVE')}：{$g(info.LiveCommissionRate)}</div>
              <div>{i18n.t('SPORTS')}：{$g(info.SportsCommissionRate)}</div>
              <div>{i18n.t('ESPORTS')}：{$g(info.ESportsCommissionRate)}</div>
              <div>{i18n.t('POKER')}：{$g(info.PokerCommissionRate)}</div>
              <div>{i18n.t('FISHING')}：{$g(info.FishingCommissionRate)}</div>
              <div>{i18n.t('COCKFIGHT')}：{$g(info.CockfightCommissionRate)}</div>
              <div>{i18n.t('LOTTERY')}：{$g(info.LotteryCommissionRate)}</div>
              <div>{i18n.t('EGAMES')}：{$g(info.EGamesCommissionRate)}</div>
              {/* 占成 RevenueShare */}
              <Typography.Text strong>{`${i18n.t('gameCategory')}-${i18n.t('revenueRate')}%`}</Typography.Text>
              <div>{i18n.t('SLOT')}：{$g(info.SlotRevenueShareRate)}</div>
              <div>{i18n.t('LIVE')}：{$g(info.LiveRevenueShareRate)}</div>
              <div>{i18n.t('SPORTS')}：{$g(info.SportsRevenueShareRate)}</div>
              <div>{i18n.t('ESPORTS')}：{$g(info.ESportsRevenueShareRate)}</div>
              <div>{i18n.t('POKER')}：{$g(info.PokerRevenueShareRate)}</div>
              <div>{i18n.t('FISHING')}：{$g(info.FishingRevenueShareRate)}</div>
              <div>{i18n.t('COCKFIGHT')}：{$g(info.CockfightRevenueShareRate)}</div>
              <div>{i18n.t('LOTTERY')}：{$g(info.LotteryRevenueShareRate)}</div>
              <div>{i18n.t('EGAMES')}：{$g(info.EGamesRevenueShareRate)}</div>
            </>}
          </>
        )
      /* 代理列表-編輯 -> 手動驗證 */
      case '46-40206':
        return (
          <>
            <div>{i18n.t('account')}：{_data?.Account}</div>
            <div>{i18n.t('verificationDate')}：{_data?.VerifyDate ? timeS2L(_data?.VerifyDate) : i18n.t('waitApproval')}</div>
          </>
        )
      /* 代理列表-編輯 -> 換線 */
      case '46-40204':
        return (
          <>
            <div>{i18n.t('upperAgentName')}：{`${agentLevelWord(_data?.UPAgentLevel)}／${_data?.UPAgentAccount}`}</div>
            <div>{i18n.t('agentAccount')}：{`${agentLevelWord(_data?.AgentLevel)}／${_data?.AgentAccount}`}</div>
          </>
        )
      /* 代理入款查詢 -> 審核-財務審核 */
      case '50-40603':
        return (
          <>
            <div>{i18n.t('agentAccount')}：{_data?.Account}</div>
            <div>{i18n.t('status')}：{i18n.t(DEPOSIT_STATE[_data?.Status])}</div>
            <div>{i18n.t('backOfficeRemarks')}：{_data?.BnRemark || '-'}</div>
            <div>{i18n.t('certificate')}：</div>
            <div>{
              _data?.ReceiptPhoto ? <Image src={_data?.ReceiptPhoto} height={40} /> : '-'
            }</div>
          </>
        )
      /* 代理調帳查詢 -> 新增、審核 */
      case '51-40703':
      case '51-40704':
        return (
          <>
            <div>{i18n.t('agentAccount')}：{_data?.OwnerAccount || _data?.AgentAccount}</div>
            <div>{i18n.t('transactionType')}：{i18n.t(ADJUST_TYPE[_data?.Type])}</div>
            <div>{i18n.t('amount')}：{toFormatNumber(_data?.Amount)}</div>
            <div>{i18n.t('rolloverRate')}：{_data?.ValidBetMultiple}</div>
            <div>{i18n.t('status')}：{i18n.t(AGENT_ADJUST_STATE[_data?.Status])}</div>
            <div>{i18n.t('backOfficeRemarks')}：{_data?.Remark || '-'}</div>
          </>
        )
      /* 代理銀行卡查詢 -> 刪除 */
      case '52-40803':
        if (_data.Data?.Crypto) {
          return (
            <>
              <div>{i18n.t('agentAccount')}：{_data?.Account}</div>
              <div>{i18n.t('currency')}：{_data?.Data?.Crypto || '-'}</div>
              <div>{i18n.t('networkType')}：{_data?.Data?.CryptoType || '-'}</div>
              <div>{i18n.t('userAddress')}：{_data?.Data?.CryptoAddress || '-'}</div>
              <div>{i18n.t('qrCode')}：{
                JSON.parse(_data?.Data?.Photo)[0]
                  ? <div><Image src={JSON.parse(_data?.Data?.Photo)[0]} height={40} /></div>
                  : '-'
              }</div>
            </>
          )
        } else {
          return (
            <>
              <div>{i18n.t('agentAccount')}：{_data?.Account}</div>
              <div>{i18n.t('bankName')}：{_data?.Data?.BankName || '-'}</div>
              <div>{i18n.t('accountNumber')}：{_data?.Data?.Account}</div>
              <div>{i18n.t('bankAccountName')}：{_data?.Data?.Name}</div>
              <div>{i18n.t('branchName')}：{_data?.Data?.Branch || '-'}</div>
              <div>{i18n.t('bankCounty')}：{_data?.Data?.State || '-'}</div>
              <div>{i18n.t('city')}：{_data?.Data?.City || '-'}</div>
              <div>{i18n.t('phoneNumber')}：{
                (_data?.Data?.PhonePrefix && _data?.Data?.PhoneNumber)
                  ? `${_data?.Data?.PhonePrefix} ${_data?.Data?.PhoneNumber}` : '-'
              }</div>
            </>
          )
        }
      /* 代理報表審核 -> 批次派發 */
      case '53-40908':
        return (
          <>
            <Typography.Text strong>{i18n.t(_data?.Type)}</Typography.Text>
            <div>{i18n.t('status')}：{i18n.t(AGENT_WITHDRAW_STATE[_data?.Status])}</div>
          </>
        )
      /* 代理報表審核 -> 手動派發 */
      case '53-40906':
        return (
          <>
            <Typography.Text strong>{i18n.t(_data?.Type)}</Typography.Text>
            {
              _data?.Data?.map((item: any, i: number) => (
                <div key={i}>
                  {item.Level === 0
                    ? <Typography.Text strong>{i18n.t('masterAgent')}：{item.AgentAccount}</Typography.Text>
                    : <Typography.Text strong>{item.Level}{i18n.t('agents')}／{i18n.t('agentAccount')}：{item.AgentAccount}</Typography.Text>}
                  <div>{i18n.t('status')}：{i18n.t(AGENT_WITHDRAW_STATE[item.Status])}</div>
                  <div>{i18n.t('originalAmount')}：{item.Amount}</div>
                  <div>{i18n.t('distributionAmount')}：{item.RealAmount}</div>
                  <div>{i18n.t('remark')}：{item.Remark || '-'}</div>
                </div>
              ))
            }
          </>
        )
      /* 前台設定 -> 彈窗設定 */
      case '58-50302':
        return <div>{i18n.t(FRON_POP_ITEM_CODE[_data?.KeyId])}：{i18n.t(ENABLE[_data?.IsPopUp])}</div>
      /* 前台設定 -> 前台設定 */
      case '75-50502':
        return <div>{i18n.t(FRON_FRONT_DESK_ITEM_CODE[_data?.KeyId])}：{i18n.t(ENABLE[_data?.Code])}</div>
      /* 站台設置 -> 編輯 */
      case '1-00102':
        if (_data?.Type === "SiteData") {
          let formattedWhiteList
          if (_data?.WhiteList && _data?.WhiteList.length > 0) {
            formattedWhiteList = _data?.WhiteList.reduce((acc: any, ip: any, index: any) => {
              if (index % 2 === 0 && index !== 0) {
                return acc + ', ' + ip + '\n';
              } else {
                return acc + ', ' + ip;
              }
            });
          }
          return (
            <>
              <div>{i18n.t('platformName')}：{_data?.SiteName || '-'}</div>
              <div>{i18n.t('systemWhitelist')}：{formattedWhiteList || '-'}</div>
            </>
          )
        }
        else {
          let displayText;
          if (_data?.Name === 'logo') {
            displayText = i18n.t('tagLogo');
          } else if (_data?.Name === 'favicon') {
            displayText = 'Favicon';
          } else {
            displayText = i18n.t(_data?.Name);
          }
          return (
            <>
              <div>{displayText || '-'}</div>
            </>
          )
        }
      /* 站台設置-遊戲資料 -> 編輯 */
      case '2-00202':
        return (
          <>
            <div>{i18n.t("gameProvider")}： {specialProviderName(_data?.Name, site.data.SiteName, _data?.Code)}</div>
            <div>{i18n.t("gameCategory")}：{i18n.t(_data?.CategoryCode)}</div>
            <div>{i18n.t("status")}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 站台設置-遊戲商排序 -> 編輯 */
      case '3-00302':
        return (
          <>
            <div>{i18n.t("gameCategory")}：{i18n.t(_data?.CategoryCode)}</div>
            <div>{i18n.t("frontSort")}：{_data?.Position}</div>
            <div>{i18n.t("gameProvider")}： {specialProviderName(_data?.Name, site.data.SiteName, _data?.Code)}</div>

          </>
        )
      /* 站台設置-遊戲清單 -> 編輯 */
      case '4-00402':
        return (
          <>
            <div>{i18n.t("gameProvider")}： {specialProviderName(_data?.ProviderName, site.data.SiteName, _data?.ProviderCode)}</div>
            <div>{i18n.t("gameCategory")}：{i18n.t(_data?.CategoryCode)}</div>
            <div>{i18n.t("frontSort")}：{_data?.Position}</div>
            <div>{i18n.t("gameName")}： {gameNameTransfer(_data?.Lang, _data?.Name)}</div>
            <div>{i18n.t("supportMultipleDevices")}：{i18n.t(ENABLE[_data?.IsMultiGameConsole])}</div>
            <div>{i18n.t("gameSetting")}：{convertGameSettings(_data?.Label)}</div>
          </>
        )
      /* 站台設置-遊戲商返水設定 -> 編輯 */
      case "5-00503":
        return (
          <>
            <div>{i18n.t("gameProvider")}： {specialProviderName(_data?.Name, site.data.SiteName, _data?.Code)}</div>
            <div>{i18n.t("cashbackState")}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 站台設置-上傳牌照 -> 新增 */
      /* 站台設置-上傳牌照 -> 編輯 */
      /* 站台設置-上傳牌照 -> 刪除 */
      case '6-00602':
      case '6-00603':
      case '6-00604':
        return (
          <>
            <div>{i18n.t("sort")}：{_data?.Position}</div>
            <div style={{
              whiteSpace: "nowrap", overflow: "hidden",
              textOverflow: "ellipsis", maxWidth: 300,
            }}>{i18n.t("title")}：{_data?.Title || "-"}</div>
            <div>{i18n.t("image")}：</div>
            <div>{
              _data?.Photo_1 ? <Image src={_data?.Photo_1} height={40} /> : "-"
            }</div>
            <div>{i18n.t("linkURL")}：{_data?.LinkURL || "-"}</div>
            <div>{i18n.t("status")}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 公告設定 -> 新增 */
      /* 公告設定 -> 編輯 */
      /* 公告設定 -> 刪除 */
      case "9-00902":
      case "9-00903":
      case "9-00904":
        return (
          <>
            <div 
              style={{
                whiteSpace: "nowrap", 
                overflow: "hidden",
                textOverflow: "ellipsis", 
                maxWidth: 300,
              }}
            >{i18n.t("title")}：{_data?.Title || "-"}</div>
            <div>{i18n.t("sort")}：{_data?.Position}</div>
            <div>{i18n.t("popup")}：{_data?.LinkTarget ? i18n.t("yes") : i18n.t("none")}</div>
            <div>{i18n.t("marquee")}：{_data?.IsMarquee ? i18n.t("yes") : i18n.t("none")}</div>
            <div>{i18n.t("status")}：{i18n.t(ENABLE[_data?.Status])}</div>
            <div>{i18n.t("publicationTime")}：{timeS2L(_data?.PublishDate)}</div>
            <div>{i18n.t("endTime")}：{timeS2L(_data?.EndTime)}</div>
            <div>{i18n.t("internalContent")}：{
              <Tooltip title={<div dangerouslySetInnerHTML={{ __html: _data?.Content }} />}>
                <div 
                  style={{
                    whiteSpace: 'nowrap', 
                    maxWidth: 600,
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis'
                  }}
                >{_data?.Content.replace(/<\/?[^>]+(>|$)/g, "")}</div>
              </Tooltip>
            }</div>
          </>
        )
      /* 維護設定 -> 新增 */
      case '93-01602':
        return (
          <>
            {_data.Settings.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <div>{i18n.t("software")}：<span
                    className="font-w-md">{item.Code === "Default" ? i18n.t("builtInCustomerServiceSystem") : item.Code}</span>
                  </div>
                  {item.Code !== "Default" && item.Type === "Url" && (
                    <div>{i18n.t("link")}：{(JSON.parse(item?.Data || "{}"))?.Url || "-"}</div>
                  )}
                  {item.Code !== "Default" && item.Type === "Qrcode" && (
                    <div>Qr Code：{(JSON.parse(item?.Data || "{}"))?.QRCode ?
                      <Image src={(JSON.parse(item?.Data || "{}"))?.QRCode} height={40} /> : "-"}</div>
                  )}
                  {item.Code !== "Default" && item.Type === "Secret" && (
                    <div>{i18n.t("customerServiceKey")}：{(JSON.parse(item?.Data || "{}"))?.entId || "-"}</div>
                  )}
                  <div>{i18n.t("status")}：{item.IsEnabled === 1 ? i18n.t("open") : i18n.t("close")}</div>
                </div>
              )
            })}
          </>
        )
      /* 維護設定 -> 新增 */
      /* 維護設定 -> 編輯 */
      /* 維護設定 -> 刪除 */
      case '11-01102':
      case '11-01103':
      case '11-01104':
        return (
          <>
            <div>{`${i18n.t("gameProvider")}／${i18n.t("mainCategory")}`}： {specialProviderName(_data?.Provider?.Name, site.data.SiteName, _data?.Provider?.Code)} / {i18n.t(_data?.Provider?.CategoryCode)}</div>
            <div>{i18n.t("maintenanceTime")}：{timeS2L(_data?.StartDate)} - {timeS2L(_data?.EndDate)}</div>
          </>
        )
      /* 權限管理-後台帳號 -> 新增 */
      case '8-00802':
        return (
          <>
            <div>{i18n.t('backOfficeAccount')}：{_data?.Account}</div>
            <div>{i18n.t('nameTitle')}：{_data?.Name}</div>
            <div>{i18n.t('password')}：{_data?.Password}</div>
            <div>{i18n.t('confirmNewPassword')}：{_data?.ConfirmPassword}</div>
            <div>{i18n.t('description')}：{_data?.Remark}</div>
            <div>{i18n.t('selectPermissions')}：{_data?.PermissionName}</div>
          </>
        )
      /* 權限管理-後台帳號 -> 編輯 */
      case '8-00803':
        return (
          <>
            <div>{i18n.t('backOfficeAccount')}：{_data?.Account}</div>
            <div>{i18n.t('nameTitle')}：{_data?.Name}</div>
            <div>{i18n.t('description')}：{_data?.Remark}</div>
            <div>{i18n.t('status')}：{i18n.t(PERMISSION_STATE[_data?.Status])}</div>
          </>
        )
      /* 權限管理-後台帳號 -> 修改密碼 */
      case '8-00804':
        return (
          <>
            <div>{i18n.t('backOfficeAccount')}：{_data?.Account}</div>
            <div>{i18n.t('password')}：{_data?.Password}</div>
            <div>{i18n.t('confirmNewPassword')}：{_data?.ConfirmPassword}</div>
          </>
        )
      /* 優惠紅包兌換碼管理 -> 新增、編輯、刪除 */
      case '82-30902':
      case '82-30903':
      case '82-30904':
        return (
          <>
            <div>{i18n.t("promotionName")}：{_data?.Name || "-"}</div>
            <div>{i18n.t("popupDisplay")}：{_data?.IsPopUp ? i18n.t("yes") : i18n.t("none")}</div>
            <div>{i18n.t("redirectToPromotionPage")}：{_data?.IsRedirect ? i18n.t("yes") : i18n.t("none")}</div>
            <div>{i18n.t("numberOfRedemptionCodeSets")}：{toFormatNumber(_data?.RewardQuantity, 0)}</div>
            <div>{`${i18n.t('redEnvelopeAmount')}／${i18n.t('sets')}`}：{toFormatNumber(_data?.RewardAmount, 4)}</div>
            <div>{i18n.t("rolloverRate")}：{_data?.ValidBetTimes}</div>
            <div>{i18n.t("status")}：{i18n.t(COUPON_STATUS[_data?.Status])}</div>
            <div>{i18n.t("sort")}：{_data?.Position}</div>
            <div>{`${i18n.t("promotionStartTime")} - ${i18n.t("endTime")}`}：{
              timeS2L(_data?.StartDate)} - {_data?.EndDate === '0000-00-00 00:00:00' ? i18n.t('permanent') : timeS2L(_data?.EndDate)
              }</div>
            <div>{i18n.t("image")}：{_data?.Photo ? <Image src={_data?.Photo} height={40} /> : "-"}</div>
            <div>{i18n.t("internalContent")}：{
              <Tooltip
                title={<div dangerouslySetInnerHTML={{ __html: _data?.Content }} />}
                overlayStyle={{ maxWidth: 400 }}
              >
                <div style={{ whiteSpace: 'nowrap', maxWidth: 400, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {_data?.Content.replace(/<\/?[^>]+(>|$)/g, "")}
                </div>
              </Tooltip>
            }</div>
          </>
        )
      /* 優惠紅包兌換碼管理 -> 匯出 */
      case "82-30905":
        return (
          <>
            <div>{i18n.t("promotionName")}：{_data?.BonusName || "-"}</div>
          </>
        )
      /* 會員標籤 -> 編輯 */
      /* 會員標籤 -> 新增 */
      case "80-11403":
      case "80-11402":
        return (
          <>
            <div>{i18n.t("tagName")}：{_data?.Name || '-'}</div>
            <div>{i18n.t("showTagName")}：{_data?.IsShowName ? i18n.t('display') : i18n.t('displayNone')}</div>
            <div>{i18n.t("tagFontColor")}：{_data?.TextColor}</div>
            <div>{i18n.t("tagBackgroundColor")}：{_data?.Color}</div>
            <div>{i18n.t("sort")}：{_data?.Position}</div>
            <div>{i18n.t("description")}：{_data?.Description || '-'}</div>
            <div>{i18n.t("status")}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 會員標籤 -> 會員帳號-編輯 */
      case "80-11404":
        return (
          <>
            {_data?.Type === "batch" && <div style={{ fontWeight: 900 }}>{i18n.t('batchRelease')}</div>}
            <div>{i18n.t("tagName")}：{_data?.TagName || '-'}</div>
            <div>{i18n.t("memberAccount")}：{_data?.Account || '-'}</div>
          </>
        )
      /* 優惠活動查詢 -> 新增*/
      case '42-30302':
        return (
          <>
            <div>{i18n.t("promotionName")}：{_data?.Name || "-"}</div>
            <div>{i18n.t("memberAccount")}：{_data?.MemberAccount || "-"}</div>
            <div>{i18n.t("transactionType")}：{i18n.t(BONUS_TICKET_TYPE[_data?.TxnType])}</div>
            <div>{i18n.t("amount")}：{toFormatNumber(_data?.Amount)}</div>
            <div>{i18n.t("rolloverRate")}：{_data?.ValidBetTimes}</div>
            <div>{i18n.t("remark")}：{_data?.Remark || "-"}</div>
          </>
        )
      /* 優惠活動查詢 -> 審核*/
      case "42-30303":
        return (
          <>
            <div>{i18n.t("promotionName")}：{_data?.Name || "-"}</div>
            <div>{i18n.t("memberAccount")}：{_data?.MemberAccount || "-"}</div>
            <div>{i18n.t("transactionType")}：{i18n.t(BONUS_TICKET_TYPE[_data?.TxnType])}</div>
            <div>{i18n.t("amount")}：{toFormatNumber(_data?.Amount)}</div>
            <div>{i18n.t("rolloverRate")}：{_data?.ValidBetTimes}</div>
            <div>{i18n.t("remark")}：{_data?.Remark || "-"}</div>
            <div>{i18n.t("status")}：{i18n.t(DISCOUNT_TYPE[_data?.Status])}</div>
          </>
        )
      /* 會員等級設定 -> 新增、編輯、刪除*/
      case "41-30202":
      case "41-30203":
      case "41-30204":
        return (
          <>
            <div>{i18n.t("levelName")}：{_data?.Name || "-"}</div>
            <div>{i18n.t("totalDeposit")}：{toFormatNumber(_data?.UPCDepositAmount)}</div>
            <div>{i18n.t("totalValidBet")}：{toFormatNumber(_data?.UPCValidBetAmount)}</div>
            <div>{i18n.t("upgradeBonus")}：{toFormatNumber(_data?.LUPROneceReward)}</div>
            <div>{i18n.t("weeklyBonus")}：{toFormatNumber(_data?.LUPRWeekReward)}</div>
            <div>{i18n.t("amountMonthly")}：{toFormatNumber(_data?.LUPRMonthReward)}</div>
            <div>{i18n.t("rolloverRate")}：{_data?.LUPRRewardValidBetTimes}</div>
          </>
        )
      case '76-30602': // 會員返水設定-編輯
        return (
          <>
            <div>{i18n.t("status")}：{i18n.t(ENABLE[_data?.Status])}</div>
            <div>{i18n.t("minimumRebate")}：{toFormatNumber(_data?.MinCommissionAmount || 0)}</div>
            <div>{i18n.t("maximumRebate")}：{toFormatNumber(_data?.MaxCommissionAmount || 0)}</div>
            <div>{i18n.t("rolloverRate")}：{_data?.CommissionValidBetMultiple}</div>
            <div>{i18n.t("distributionMethod")}：{i18n.t(SETTLE_METHOD[_data?.SettleMethod])}</div>
            <div>{i18n.t("instantRebateStatus")}：{i18n.t(ENABLE[_data?.Instant])}</div>
            {_data?.Settings?.map((setting: any, index: any) => (
              <div key={index}>
                <div className="font-w-md">{i18n.t("levelName")}：{setting?.Name}</div>
                <div className="ml-1">{i18n.t("SLOT")}：{`${$g(setting?.SlotCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("LIVE")}：{`${$g(setting?.LiveCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("SPORTS")}：{`${$g(setting?.SportsCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("POKER")}：{`${$g(setting?.PokerCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("FISHING")}：{`${$g(setting?.FishingCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("LOTTERY")}：{`${$g(setting?.LotteryCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("EGAMES")}：{`${$g(setting?.EGamesCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("ESPORTS")}：{`${$g(setting?.ESportsCommissionRate)} %`}</div>
                <div className="ml-1">{i18n.t("COCKFIGHT")}：{`${$g(setting?.CockfightCommissionRate)} %`}</div>
              </div>
            ))}
          </>
        )
      /* 全民代理設置 -> 編輯*/
      case "86-80102":
        const bonusRuleSetting = JSON.parse(_data.BonusRuleSetting)

        return (
          <>
            <div>{i18n.t('promotionName')}：{_data.Name}</div>
            <div>{i18n.t('peopleAgentStatus')}：{i18n.t(PEOPLE_AGENT_STATUS[_data.Status])}</div>
            <div>{i18n.t('levelSettlementTime')}：{i18n.t(REBATE_PERIOD[_data.Period])}</div>
            <div>{i18n.t('minimumRebate')}：{bonusRuleSetting.CommissionMinimun}</div>
            <div>{i18n.t('maximumRebate')}：{bonusRuleSetting.CommissionMaximun}</div>
            <div>{i18n.t('distributionMethod')}：{i18n.t(SETTLE_METHOD[_data?.SettleMethod])}</div>
            <div className="font-w-md">{i18n.t('peopleAgentLevelName')}</div>
            <div className="ml-1">Level 1：{bonusRuleSetting.Settings[0].Name}</div>
            <div className="ml-1">{i18n.t('status')}：{i18n.t(ENABLE[bonusRuleSetting?.Settings[0].IsEnabled ? 1 : 0])}</div>
            <div className="font-w-md">{i18n.t('validMemberCriteria')}</div>
            <div className="ml-1">{i18n.t('requiredDepositAmount')}：{bonusRuleSetting?.Settings[0].DirectMembership.TotalDepositAmount}</div>
            {bonusRuleSetting?.Settings.map((setting: any, index: any) => {
              if (index === 0 || !setting?.Name) return <></>
              return (
                <>
                  <div className="font-w-md">{i18n.t("upgradeConditions")}</div>
                  <div className="ml-1">{i18n.t("personalAccumulatedDepositAmount")}：{setting.Membership.TotalPersionalDepositAmount}</div>
                  <div className="ml-1">{i18n.t("numberOfDirectDownlineMembers")}：{setting.Membership.DirectMemberCount}</div>
                  <div className="ml-1">{i18n.t("accumulatedDepositDmountOfDownlineDirectMembers")}：{setting.Membership.TotalDirectMemberDepositAmount}</div>
                  <div className="ml-1">{i18n.t("numberOfValidDirectMembers")}：{setting.Membership.ValidDirectMemberCount}</div>
                  <div className="ml-1">Level {index + 1}：{setting.Name}</div>
                  <div className="ml-1">{i18n.t("status")}：{i18n.t(ENABLE[setting.IsEnabled ? 1 : 0])}</div>
                </>
              )
            })}
          </>
        )
      /* 全民代理查詢 -> 換線*/
      case "87-80203":
        return (
          <>
            {type === 'after' && <div>{i18n.t('changeMemberMethod')}：{i18n.t(CHANGE_MEMBER_METHOD[_data.Type - 1])}</div>}
            {_data.Type === 1 && (
              <>
                {type === 'before' && (
                  <>
                    <div>{i18n.t('changingTheLineMemberAccount')}：{_data.MemberAccounts?.length ? _data.MemberAccounts.join('、') : '-'}</div>
                    <div>{i18n.t('originalUpperLevelMemberAccount')}：{_data.UpMembarAccounts?.length ? _data.UpMembarAccounts.join('、') : '-'}</div>
                  </>
                )}
                {type === 'after' && (
                  <>
                    <div>{i18n.t('currencyExchange')}{i18n.t('memberAccount')}：{_data.MemberAccounts?.length ? _data.MemberAccounts.join('、') : '-'}</div>
                    <div>{i18n.t('targetMemberAccount')}：{_data.TargetMemberAccount || '-'}</div>
                  </>
                )}
              </>
            )}
            {_data.Type === 2 && (
              <>
                {type === 'before' && (
                  <>
                    <div>{i18n.t('changingTheLineMemberAccount')}：{_data.MemberAccount || '-'}</div>
                    <div>{i18n.t('originalUpperLevelMemberAccount')}：{_data.UpMemberAccount || '-'}</div>
                  </>
                )}
                {type === 'after' && (
                  <>
                    <div>{i18n.t('memberAccount')}：{_data.MemberAccount || '-'}</div>
                    <div>{i18n.t('offlineDirectMemberAccounts')}：{_data.DownlineMemberAccounts?.length ? _data.DownlineMemberAccounts.join('、') : '-'}</div>
                    <div>{i18n.t('targetMemberAccount')}：{_data.TargetMemberAccount || '-'}</div>
                  </>
                )}
              </>
            )}
          </>
        )
      /* 全民代理返水查詢 -> 審核、重計*/
      case "88-80303":
        return (
          <div>{i18n.t(_data.Type === 1 ? 'distributeAll' : 'batchReject')}</div>
        )
      case "88-80304":
        return (
          <>
            <div>{i18n.t('date')}：{timeL2SDay(dayjs(_data?.Period?.StartDate), 'YYYY-MM-DD HH:mm:ss')} ~ {timeL2SDay(dayjs(_data?.Period?.EndDate), 'YYYY-MM-DD HH:mm:ss')}</div>
            <div>
              {i18n.t("gameCategory")}：
              {_data?.CategoryCodes && _data.CategoryCodes.length > 0 ? _data?.CategoryCodes?.map((item: any, index: number) => {
                return <React.Fragment key={index}>{i18n.t(item)}{index !== _data.CategoryCodes.length - 1 && '、'}</React.Fragment>
              }) : i18n.t('ALL')}
            </div>
            <div>{i18n.t('memberAccount')}：{_data.MemberAccounts || i18n.t('ALL')}</div>
          </>
        )
      /* 會員群組 -> 新增、編輯、刪除*/
      case "89-11602":
      case "89-11603":
      case "89-11604":
        return (
          <>
            <div>{i18n.t("groupName")}：{_data?.Name || "-"}</div>
            <div>{i18n.t("singleDayWithdrawalLimit")}：{toFormatNumber(_data?.WithdrawAmountPerDay)}</div>
            <div>{i18n.t("status")}：{i18n.t(ENABLE[_data?.Status])}</div>
          </>
        )
      /* 會員群組 -> 批次修改群組*/
      case "89-11605":
        return (
          <>
            <div>{i18n.t("sourceGroup")}：{_data?.SourceMemberGroup?.Name || "-"}</div>
            <div>{i18n.t("targetGroup")}：{_data?.TragetMemberGroup?.Name || "-"}</div>
          </>
        )
      /* 會員群組-會員列表 -> 批次設定*/
      case "90-11702":
        return (
          <>
            <div>{type === 'before'? i18n.t("sourceGroup") : i18n.t("targetGroup")}：{_data?.MemberGroupName || "-"}</div>
            <div>{i18n.t("memberAccount")}：{_data?.MemberAccounts.join('、')}</div>
          </>
        )
      /* 會員群組-會員列表 -> 鎖定會員群組*/
      case "90-11703":
        return (
          <>
            <div>{i18n.t("lockMemberGroup")}：{i18n.t(MEMBER_GROUP_LOCK_STATUS_YES_NONE[_data?.IsLockMemberGroup])}</div>
            <div>{i18n.t("memberAccount")}：{_data?.MemberAccounts.join("、")}</div>
          </>
        )
      case '85-50602': // 版型設置-編輯
        return (
          <>
            <div className="font-w-md">Desktop</div>
            <div>{i18n.t("layout")}：{_data?.Desktop?.layout.toUpperCase() || "-"}</div>
            <div>{`0${_data?.Desktop?.themeIndex}` || "-"}</div>
            <div className="font-w-md">Mobile</div>
            <div>{i18n.t("layout")}：{_data?.Mobile?.layout.toUpperCase() || "-"}</div>
            <div>{`0${_data?.Mobile?.themeIndex}`  || "-"}</div>
          </>
        )
      default:
        return ('-')
    }
  } else return input;
}